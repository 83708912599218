import React from "react";
import  './Services.css'
function Services() {
    return (
        <div className=" services-bg">
            <div className="container services-bg">
            <div className="services-title">  
       <h6>I Our Digital Services</h6>
       <h1>Serving Professional<br/>
Services We’Re Offering</h1>
</div>
<div className="row ">
<div className="col-sm-3 ">
<div className="services-image">
<img src="img/marketing.jpg"  alt="our-mission"/>
<h4>Social mediaa marketing</h4>
<h5>Lends a Voice to your Brand. We<br/> 
make you relevant and available.</h5>
    </div>
    </div>
    <div className="col-sm-3 ">
    <div className="services-image">
    <img src="img/website-design.jpg"  alt="our-mission"/>
    <h4>Website Design & Development  </h4>
    <h5>More than websites we build a online<br/>
property that lasts.</h5>
    </div>
    </div>
    <div className="col-sm-3 ">
    <div className="services-image">
    <img src="img/mobile-app.jpg"  alt="our-mission"/>
    <h4>APP Design & Development  </h4>
    <h5>More than App we build a online<br/>
property that lasts.</h5>
    </div>
    </div>
    <div className="col-sm-3">
    <div className="services-image">
    <img src="img/host.jpg"  alt="our-mission"/>
    <h4> Web Hosting </h4>
    <h5>A reputable web host keeps your site online and helps you avoid unnecessary downtime.</h5>
    </div>
    </div>
</div>
</div>
        </div>
        );

    }
    
    export default Services;