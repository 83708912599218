import React from "react";
import  './header.css'
import {Link} from "react-router-dom";

function Header() {
  return (
    <div>
      <div className="container-fluid nav_bg">
      <div className="row">
      <div className="col-sm-10 mx-auto">
<nav className="navbar navbar-expand-lg navbar-light ">
  <div className="container-fluid">
    <Link to="/" className="navbar-brand"><img src="img/Logo.png" class="logo-size" alt="slider"/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about-us" className="nav-link">About</Link>
        </li>
        <li className="nav-item">
          <Link to="/Service" className="nav-link">Service</Link>
        </li>
        <li className="nav-item">
        <Link to="/Contacts" className="nav-link">Contacts</Link>
        </li>
 
 
      </ul>

    </div>
  </div>
</nav>
</div>
      </div>

      </div>
</div>
  );

}

export default Header;
