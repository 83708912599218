import React from "react";
import Header from '../component/header/Header';
import Banner from '../component/banner/Banner';
import About from '../component/about/About';
import Services from '../component/services/Services';
import Testmonial from '../component/testmonial/Testmonial';
import Technologies from '../component/technologies/Technologies';
import Contact from '../component/contact/Contact';
function Home() {
  return (
    <div>
      <Header/>
      <Banner/>
      <About/>
      <Services/>
      <Testmonial/>
      <Technologies/>
      <Contact/>
 
    </div>
 
  );
}

export default Home;