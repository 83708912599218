import React from "react";
import Header from '../component/header/Header';
import Contact from '../component/contact/Contact';
import Delivering from '../component/delivering/Delivering';
import Explore from '../component/Explore/Explore';
import Specialization from '../component/Specialization/Specialization';
function Service() {
 return (
    <div>
      <Header/>
      <Delivering />
      <Explore />
      <Specialization />
      <Contact/>
    </div>
  );
}

export default Service;