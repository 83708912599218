import React from "react";
import  './Testmonial.css'

function Testmonial() {
  return (
    <div>

<div className="container">
<div className="about-section">
<div className="row">
<div className="col-sm-6">
<img src="img/testmonial.jpg" class="about-img" alt="about"/>
  </div>
  <div className="col-sm-6">
  <div className="align-right">
    
      <h1>We’re Here Help Of 
Choosing Our Business
Agency</h1>
<div className="testmonial-section">
  <div className="emp-section">
    <div className="emp-img">
      <img src="https://resiliencesoft.com/assets/images/saubhagya.jpg" className="about-img" alt="about"/>
      </div>
      <div className="person-detail">
        <h5>Saubhagya Singh</h5>
        <h6>Senior Web Devloper &amp; Team leader</h6>
        <div class="under-line"></div>
        </div>
        </div>
        <p>Here we are help to choose best service agency,
           Our sole aim is to make you get recognition 
           in the online market. Evolving your business 
           to a newer height is our business.</p>
           </div>
           <div className="testmonial-section">
             <div className="emp-section">
               <div className="emp-img">
                 <img src="https://old.resiliencesoft.com/images/manish.jpg" class="about-img" alt="about"/>
                 </div>
                 <div className="person-detail">
                   <h5>Manish Chandra</h5>
                   <h6>App Developer </h6>
                   <div className="under-line">
                     </div>
                     </div>
                     </div>
                     <p>A developer is an individual that builds
                        and create software and applications.
                         He or she writes, debugs and executes
                          the source code of a software application.
                           A developer is also known as a software developer,
                            computer programmer, programmer, software coder
                             or software engineer.</p>
                             </div>
    
  </div>





</div>
</div>
</div>

        </div>
</div>
  );

}

export default Testmonial;
