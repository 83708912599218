import React from "react";
import Header from '../component/header/Header';
import Contact from '../component/contact/Contact';
import Privacy from "../component/PrivacyPolicy/Privacy";

function PrivacyPolicy() {
    return (
      
       <div>
          
         <Header/>
       <Privacy/>
         <Contact/>
       
       </div>
      
     );
   }
   export default PrivacyPolicy;