import React from "react";
import Header from '../component/header/Header';
import Contact from '../component/contact/Contact';
import Terms from '../component/Terms/Terms';


function TermsCondition() {
 return (
    <div>
      <Header/>
       <Terms/>
      <Contact/>
    </div>
  );
}

export default TermsCondition;