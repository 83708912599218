import React from "react";
import  './Banner.css'
function Banner() {
    return (
        <div>
      
              <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item slider active">
 
      <div className="banner-text">
      <h5>WE ARE HERE TO</h5>
      <h3>DIGITALLY TRANSFORM<br/>
YOUR BUSINESS</h3>
<div className="banner-line"></div>
      </div>
  
    </div>
    <div className="carousel-item slider-i">

      <div className="banner-text">
      <h5>WE ARE HERE TO</h5>
      <h3>DIGITALLY TRANSFORM<br/>
YOUR BUSINESS</h3>
<div className="banner-line"></div>
      </div>

    </div>
    <div className="carousel-item slider-ii">

      <div className="banner-text">
      <h5>WE ARE HERE TO</h5>
      <h3>DIGITALLY TRANSFORM<br/>
YOUR BUSINESS</h3>
<div className="banner-line"></div>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>

        );

    }
    
    export default Banner;