import React from "react";
import  './Choose.css'

function Choose() {
  return (
    <div>
      <div className="Choose-title-section">
<div className="container">
<div className="Choose-title">
  <h1>Why Choose Us </h1>
  <h5>Digital Score Offers Customers 8 years of experience and knowledge of how to sell more. providing fast and high quality project management.</h5>
<div className="group">
<div className="row ">
  <div className="col-sm-3">
  <img src="img/Commitment.png"  alt="Commitment"/>
  <h5>Commitment to work</h5>
  </div>
  <div className="col-sm-3">
  <img src="img/cost.png"  alt="cost"/>
  <h5>Cost Effective</h5>
  </div>
  <div className="col-sm-3">
  <img src="img/team.png"  alt="team"/>
  <h5>Solid Teamwork</h5>
  </div>
  <div className="col-sm-3">  <img src="img/Standard.png"  alt="Standard"/>
  <h5>Standard Of Excellence</h5></div>
</div>
</div>
</div>
</div>
</div>
</div>
  );

}

export default Choose;
