import React from "react";
import Header from '../component/header/Header';
import Contact from '../component/contact/Contact';
import Cancel from '../component/Cancel/Cancel';



function Cancellation() {
 return (
    <div>
      <Header/>
<Cancel/>
      <Contact/>
    </div>
  );
}

export default Cancellation;