import React from "react";
import  './WeAre.css'

function WeAre() {
  return (
    <div>

<div className="container">
<div className="about-section">
<div className="row">
<div className="col-sm-5">
<img src="img/weare.jpg" class="about-img" alt="about"/>
  </div>
  <div className="col-sm-7">
  <div className="align-right">
    
      <h1>We are Mindwave  IT Solutions</h1>
<h5><p>We, at Mindwave IT Solutions , understand the importance and results of having appropriate developed websites with great web design and user experience. Our website developers provide expert web application development and web design services to our clients. Our web development is optimized for search engines to get more website traffic or you can say SEO friendly, for social media and digital marketing.</p>
<p>We are more than software programmers – we are critical thinkers, problem-solvers, crafters of change, and innovators of digital transformation. We help businesses; small and big, to bring value to their end customers , beat their competition.</p></h5>
    
  </div>





</div>
</div>
</div>

        </div>
</div>
  );

}

export default WeAre;
