import React from "react";
import Header from '../component/header/Header';
import Contact from '../component/contact/Contact';
import GetInTouch from '../component/GetInTouch/GetInTouch';

function Contacts() {
  return (
    <div>
      <Header/>
      <GetInTouch />
 
      <Contact/>
    </div>
 
  );
}

export default Contacts;