import React from "react";
import  './About.css'
function About() {
    return (
        <div>

<div className="container">
<div className="about-section">
<div className="row">
<div className="col-sm-6">
<img src="img/about.jpg" className="about-img" alt="about"/>
  </div>
  <div className="col-sm-6">
  <div className="align-right">
      <h6>I About Us</h6>
      <h1>The Best Choice For Your Successful Business.</h1>
<h5>"Mindwave IT Solutions is a Leading Website & SMS Marketing Company based in INDIA. Every business, whether small, mid size or large, strives for reaching the zenith. In the present times, when the entire world seems to be going online through Web Designing , Google Adwords, Bulk SMS, Bulk Email, Web Hosting, Reseller Hosting having website is a must for any business to reach out to maximum potential customers."</h5>
<h5>We provide end-to-end Digital Marketing, Software and Mobile Application Development services. We take you from creating the concept and design, through to developing and delivering the final product that will delight your customers. We'll do everything we can to make our next best project!</h5>
  </div>
  </div>
  <div className="about-bottom-box"><div className="row">
    <div className="col-sm-6"><div className="our-mission">
      <div className="our-mission-img">
        <img src="img/our-mission.png" alt="our-mission"/>
        </div><div className="our-mission-text">
          <h5>Our Mission</h5>
          <p>Our mission is to enhance business growth of our customers with creative design, development and to deliver market defining high quality solutions that create value and reliable competitive advantage to customers around the globe.</p>
          </div></div></div><div className="col-sm-6">
            <div className="our-mission">
              <div className="our-mission-img">
                <img src="img/our-vision.png" alt="our-mission"/></div>
          <div className="our-mission-text">
            <h5>Our Vision</h5>
            <p>No matter which industry you belong to, every business must become digital. Mindwave IT Solutions is one of the prominent software companies that helps other organizations to transform into digital enterprises</p>
  </div>
  </div>
  </div>
  </div>
  </div>
</div>

</div>

        </div>
        </div>
 );
    }

    export default About;