import React from "react";
import  './Delivering.css'

function Delivering() {
  return (
    <div>
      <div className="container ">
      <div className="delivering-padding">
      <div className="row">
      <div className="col-sm-6">
        <div className="delivering-section">
        <h6>Mindwave  IT  solutions Software company</h6>
        <h1>We help global brands
develop digital world       
</h1>
<p>We specialise in delivering end-to-end services
and mobile & web applications for innovation
companies  arround the world. </p>
<div className="delivering-btn">
  <a  className="estimate-project" href="Estimate Project" alt="">Estimate Project</a>
  <a  className="our-portfolio" href="Our Portfolio" alt="">Our Portfolio</a>
</div>
</div>
        </div>
        <div className="col-sm-6">
          <div className="delivering-img">
        <img src="img/delivering.jpg"  alt="our-mission"/>
        </div>
        </div>
        </div>
  
      </div>

</div>
</div>
  );

}

export default Delivering;
