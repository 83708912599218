import React from "react";
import  './Cancel.css'

function Cancel() {
  return (
<div>
<div class="container">
	<div class="terms-section">
		<h1><strong>Cancellation</strong></h1>
		<p>Please be advised that cancellations made up to [48 hours] 
			before a scheduled appointment via [email, text, phone call]
			 will be processed without a penalty.

Cancellations made [48 hours] or less before an appointment will
 be subject to a charge of [half of the service rate]. This
  includes appointments where our service provider is unable
   to access the property, is turned away, or the client is unavailable.

If [Company Name] cancels an appointment with less than [48 hours notice],
	 a new appointment will be scheduled without penalty to the client, subject
	  to availability.</p>


		<p>At Mind Wave IT Solution, we have full confidence in our varied range of services, products and e-business solutions. We leave no stone unturned in providing our esteemed clients fast, reliable and exceptional service guarantee every time in time we do business with them. All the services available at BrainPulse's website are carried out for the clients after extensive project analysis using a complete scope document. It ensures full understanding of the work and almost no possibilities of any project cancellation, reversal or dispute. However, any refund and service cancellation is taken place abiding a set of conditions.</p>
		<h3>Cancellation Policy</h3>
	<p>A subscriber can place the cancellation order for a particular service within 7-10 days of service agreement via prescribed cancellation form available on our website.
No cancellation is possible for the services offered with promotional discounts on special occasions viz. Independence Day, Christmas and New Year. These are limited edition offers and hence any range of refund would not be possible.
In no case, BrainPulse will entertain cancellation requests made for services such as domain name registration and domain name transfer.
Fee once paid will not be refunded in any case even if the cancellation is posted within 12 hours.
A service stands cancel if next payment is not received within 7 working days</p>
<h3>Refund Policy</h3>
	<p>Every service offered by us takes different course of action to get accomplished from start to end thus refund policy differs from one service to other.
There is always a certain set of resources that are involved in achieving every milestone of a campaign. Thus, a refund would not be entertained for the work already completed.
No refund is possible for the fee paid in the form of administrative fees or installation charges.
Taxes paid on services levied by regulatory bodies would not be refunded with fee refund.
No refund will be entertained in case service gets canceled due to any violation of terms stated in our Terms & Condition section.
There is no refund policy for the services placed under same day delivery.
BrainPulse is not liable to pay refund if delay or service disruption happens due to third party involvement.</p>


	</div>
</div>
</div>


  );

}

export default Cancel;
