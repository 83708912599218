import React from "react";
import  './Specialization.css'
function Specialization() {
    return (
<div >
<div className="Specialization-section ">
<div className="container ">

<h1>Our Company
Specialization</h1>
<p>Mindwave  IT  solutions is on international software and services company,
with offices 
in india. our aim create applications that make an impact for
your organization and we help reducing your cost.</p>
<div className="Specialization-part">
<div className="row">

<div className="col-sm-3">
<div className="Specialization-box">
      <img src="img/digital-mark.jpg"  alt="our-mission"/>
    <h5>Digital Marketing</h5>
</div>
</div>

<div className="col-sm-3">
<div className="Specialization-box">
      <img src="img/web-develop.jpg"  alt="our-mission"/>
    <h5>Web Development</h5>
</div>
</div>
<div className="col-sm-3">
<div className="Specialization-box">
      <img src="img/app-devlop.jpg"  alt="our-mission"/>
    <h5>App Development</h5>
</div>
</div>
<div className="col-sm-3">
<div className="Specialization-box">
      <img src="img/software-development-team.png"  alt="our-mission"/>
    <h5>
       Software Development</h5>
</div>
</div>
</div>
</div>

</div>

</div>
</div>

        );

    }
    
    export default Specialization;