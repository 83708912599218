import React from "react";
import Header from '../component/header/Header';
import Contact from '../component/contact/Contact';
import WeAre from "../component/weare/WeAre";
import Choose from "../component/Choose/Choose";


function About() {
  return (
    <div>
      <Header/>
      <WeAre/>
      <Choose/>

      <Contact/>
      
    </div>
 
  );
}

export default About;