import React from "react";
import  './Technologies.css'

function Technologies() {
  return (
    <div>
      <div className="technologies-box">
<div className="container">

<div className="technologies-title">
  <h1>We work on Technologies</h1>
  <div className="work-img">
    <div className="row">
      <div className="col-sm-3">
        <div className="animation">
          <img src="img/ps.png" alt="Photoshop"/>
          <h5>Photoshop</h5>
          </div>
          </div>
          <div className="col-sm-3">
            <div className="animation">
              <img src="img/php.png" alt="php"/>
              <h5>PHP</h5>
              </div>
              </div>
              <div className="col-sm-3">
                <div className="animation">
                  <img src="img/iso.png" alt="iso"/>
                  <h5>ISO</h5>
                  </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="animation">
                      <img src="img/wp.png" alt="wordpress"/>
                      <h5>Wordpress</h5>
                      </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="animation">
                          <img src="img/html.png" alt="about"/>
                          <h5>HTML CSS </h5>
                          </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="animation">
                              <img src="img/net.png" alt="about"/>
                              <h5>.Net</h5>
                              </div>
                              </div>
                              <div className="col-sm-3">
                                    <div className="animation">
                                  <img src="img/and.png" alt="about"/>
                                  <h5>Android</h5>
                                  </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="animation">
                                      <img src="img/joomla.png" alt="about"/>
                                      <h5>Joomla</h5>
                                      </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="animation">
                                          <img src="img/angular.png" alt="about"/>
                                          <h5>Angular Js</h5>
                                          </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="animation">
                                              <img src="img/node-js.png" alt="about"/>
                                              <h5>Node Js</h5>
                                              </div>
                                              </div>
                                              <div className="col-sm-3">
                                                <div className="animation">
                                                  <img src="img/ionic.png" alt="about"/>
                                                  <h5>Photoshop</h5>
                                                  </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                    <div className="animation">
                                                      <img src="img/magento.png" alt="about"/>
                                                      <h5>Magento</h5>
                                                      </div>
                                                      </div>
                                                      </div>
                                                      </div>
</div>
</div>
</div>

</div>
  );

}

export default Technologies;
