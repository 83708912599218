import React from "react";
import { Link } from "react-router-dom";
import  './Contact.css'

function Contact() {
  return (
    <div  className="whatsapp">

      <hr/>
      <div className="container">
      <div className="contact-section">
      <div className="row">

<div class="col-sm-4">
  <h1>Contact Us</h1>
  <h5>Location</h5>
<h6>INFRONT OF SBI ADB BRANCH 1ST FLOOR MAIN
ROAD  VYAPAR VIHAR BILASPUR CHHATTISGARH 495004</h6>
<p>
Contact no. +91 9109911375</p>
<div className="contact-media-icon">
      <img src="img/facebook.png" alt="facebook" />
      <img src="img/twitter.png" alt="twitter" />
      <img src="img/Instagram.png" alt="Instagram" />

      </div>
</div>
<div className="col-sm-4"></div>
<div className="col-sm-4">

</div>
      </div>
 
     
</div>
</div>
<div className="whatsapp-img">
  <a href="WhatsApp">
<img src="img/WhatsApp.png" alt="WhatsApp"  /></a>
<div className="footer"><h5>COPYRIGHT © 2022 
<Link to="/"> Mindwave IT Solutions. </Link>
    ALL RIGHTS RESERVED</h5>
   <div className="term-conditons-privacy">
     <Link to="/TermsCondition"> Terms and Conditions</Link>
     <Link to="/PrivacyPolicy"> Privacy Policy</Link>
     <Link to="/Cancellation"> Cancellation</Link>
     </div>
     </div>
</div>
</div>
  );

}

export default Contact;
