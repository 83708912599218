import './App.css';
import Home from './pages/Home' ;
import About from './pages/About' ;
import Service from './pages/Service' ;
import Contacts from './pages/Contacts' ;
import Cancellation from './pages/Cancellation';
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import {BrowserRouter,Routes,Route,} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route exact path="/about-us" element={<About />}></Route>
      <Route exact path="/service" element={<Service />}></Route>
      <Route exact path="/Contacts" element={<Contacts />}></Route>
      <Route exact path="/TermsCondition" element={<TermsCondition/>}></Route>
      <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>}></Route>
      <Route exact path="/Cancellation" element={<Cancellation/>}></Route>
      </Routes>
      </BrowserRouter>
    </div>
 
  );
}

export default App;