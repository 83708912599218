import React from "react";
import  './GetInTouch.css'
function GetInTouch() {
    return (
        <div>

<div className="container">
<div className="contact-details-box">
<div className="row">
<div className="col-sm-4">
<div className="contact-details">
<div className="contact-margin">
<h5>Get in touch</h5>
<h6>We'love to hear from you. Our friendly team
is always here to 
chat.</h6>
  </div>
  <div className="contact-margin">
<h5>Chat to us</h5>
<h6>Our friendly team is here to help.</h6>

<p>info@mindwave.com</p>
  </div>
  <div className="contact-margin">
<h5>Office</h5>
<h6>INFRONT OF SBI ADB BRANCH 1ST FLOOR MAIN
ROAD  VYAPAR VIHAR BILASPUR CHHATTISGARH 495004</h6>
<p>Mindwave IT Solution
</p>
  </div>
  <div className="contact-margin">
<h5>Phone
</h5>
<h6>Mon-Fri from 8am to 5pm.</h6>

<p>+919109911375</p>
  </div>

</div>

</div>

<div className="col-sm-8">
<div className="contact-form">
  <h1>Level up your brand</h1>
  <h6>You can reach us anytime via info@mindwave.com</h6>

  <form >
    <label >First</label><br/>
    <input type="text"  /><br/>

    <label >Email</label><br/>
    <input type="text" /><br/>

    

    <label >Phone Number</label><br/>
    <input /><br/>
    <label >Services</label><br/>
    <input /><br/>

  
  

<label >
How can  we help?</label><br/>
<textarea  rows="4" cols="50">
  
</textarea><br/>
  <button  className="get-started-btn" >Get started</button>
</form>

</div>
</div>
</div>
</div>

</div>

        </div>
              
                        );
    }

    export default GetInTouch;