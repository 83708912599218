import React from "react";
import  './Explore.css'
function Explore() {
    return (
<div>
   <div className="Explore-section "><div className="container ">
      <h1>Explore what we offer</h1>
      <div className="offer-box">
         <p>We offer a unique combination of project
             management experiences with core engineering 
             background along with information technology 
             skills in the manufacturing and process industry
              domain. We design unique, innovative and customized 
              products that allow our clients to automate existing
               processes, which increases their productivity
                and reduces costs.</p>
                <div className="row">
                   <div className="col-sm-3">
                      <div className="explore-item">
                         <img src="img/smm.png" alt="our-mission"/>
                         <h5>Ideation and Strategy</h5>
                         <p>we offer experience in building a new product</p>
                         </div>
                         </div>
                         <div className="col-sm-3">
                            <div className="explore-item"> 
                                  <img src="img/web-dv.png" alt="our-mission"/>
                                  <h5>Product Design</h5>
                                  <p>We will create a beautiful and
                                      working design for your idea</p>
                                      </div>
                                      </div>
                                      <div className="col-sm-3">
                                         <div className="explore-item">
                                        <img src="img/mobile-dv.png" alt="our-mission"/>
                                        <h5>Mobile &amp; Web Development</h5>
                                        <p>We specialise in delivering end-to-end services</p>
                                        </div>
                                        </div>
                                        <div className="col-sm-3">
                                           <div className="explore-item">
                                            <img src="img/software-dv.png" alt="our-mission"/>
                                            <h5>Low-code Development</h5>
                                            <p>We will  implement your idea
                                                based on low-code technology</p>
                                                </div>
                                                </div>
                                                <div className="col-sm-3">
                                                   <div className="explore-item">
                    <img src="img/web-hosting-icon.png" alt="our-mission"/>
                    <h5>Web Hosting</h5>
                    <p>Mindwave IT solutions Web Hosting solutions 
                       enable its clients to adeptly host a
                        website with full cPanel access along 
                        with many additional websites.</p>
                        </div>
                        </div>
                        <div className="col-sm-3">
                           <div className="explore-item"> 
                                 <img src="img/bulk-sms.png" alt="our-mission"/>
                                 <h5>Bulk SMS Services</h5>
                                 <p>"Bulk SMS needs no introduction. 
                                    We all know that SMS stands for
                                     Short Messaging Service.</p>
                                     </div>
                                     </div>
                                     <div className="col-sm-3">
                                        <div className="explore-item">
                              <img src="img/training.png" alt="our-mission"/>
                              <h5>Professional Training</h5>
                              <p>"Development of universal, 
                                 professional competencies and
                                  means students, mediated a
                                   different level of training,
                                    personal experience and 
                                    attitude to the future profession</p>
                                    </div>
                                    </div>
                                    <div className="col-sm-3">
                                       <div className="explore-item">  
              <img src="img/graphic-design.png" alt="our-mission"/>
              <h5>Graphic Designing</h5>
              <p>Whether you need a new design for a custom built website or a design for a popular CMS platform you'll find the perfect web design with these design services.</p></div></div></div></div></div></div></div>
        );

    }
    
    export default Explore;